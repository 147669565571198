import request from '@/utils/request'

// 添加资源空间
export function addIotSpace(data) {
    return request({
      url: '/iot/space/add',
      method: 'post',
      data: data
    })
}

// 获取资源空间
export function getIotSpace(id) {
    return request({
      url: '/iot/space/get?id=' + id,
      method: 'get'
    })
}

// 更新资源空间
export function updateIotSpace(data) {
    return request({
      url: '/iot/space/update',
      method: 'post',
      data: data
    })
  }
  
// 删除资源空间
export function delIotSpace(id) {
    return request({
      url: '/iot/space/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 资源空间列表
export function listIotSpace(query) {
  return request({
    url: '/iot/space/list',
    method: 'get',
    params: query
  })
}
