import request from '@/utils/request'

// 添加产品
export function addIotProduct(data) {
    return request({
      url: '/iot/product/add',
      method: 'post',
      data: data
    })
}

// 获取产品
export function getIotProduct(id) {
    return request({
      url: '/iot/product/get?id=' + id,
      method: 'get'
    })
}

// 更新产品
export function updateIotProduct(data) {
    return request({
      url: '/iot/product/update',
      method: 'post',
      data: data
    })
  }
  
// 删除产品
export function delIotProduct(id) {
    return request({
      url: '/iot/product/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 产品列表
export function listIotProduct(query) {
  return request({
    url: '/iot/product/list',
    method: 'get',
    params: query
  })
}