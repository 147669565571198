import request from '@/utils/request'

// 添加产品服务
export function addIotProductService(data) {
    return request({
      url: '/iot/productservice/add',
      method: 'post',
      data: data
    })
}

// 获取产品服务
export function getIotProductService(id) {
    return request({
      url: '/iot/productservice/get?id=' + id,
      method: 'get'
    })
}

// 更新产品服务
export function updateIotProductService(data) {
    return request({
      url: '/iot/productservice/update',
      method: 'post',
      data: data
    })
  }
  
// 删除产品服务
export function delIotProductService(id) {
    return request({
      url: '/iot/productservice/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 产品服务列表
export function listIotProductService(query) {
  return request({
    url: '/iot/productservice/list',
    method: 'get',
    params: query
  })
}