<template>
  <div class="app-container">
    <el-row>
      <el-col :span="24" class="card-box">
        <el-card style="position: relative;">
          <div slot="header">
            
            <tr style=" display:inline-block ;">
              
              <td>
                <div style="display:inline-block;font-weight:bolder;">
                  {{ form.name }}                
                </div>
              </td>
              <td>
                <div  style="display:inline-block;margin-left:10px;font-size:13px;color:#859094;">
                  ID:{{ form.product_code }}
                </div>
              </td>
              <td>
                <div  style="display:inline-block;margin-left:10px;font-size:13px;color:#859094;">
                  | 注册设备数量: {{ form.device_count }}
                </div>
              </td>     
            </tr>
            <div style=" display:inline-block ; float:right">  
                <el-button                 
                  style="margin-left: 10px"
                  type="danger"                 
                  size="mini"   
                  @click="updateProduct"                                
                >更新产品信息</el-button>
              </div>
              <div  style = "clear:both;" ></div >           
          </div>
          <div>
            <table style="width: 100%;">              
              <tbody>
                <tr >
                  <td><div class="cell" style="margin-top:10px;font-size:13px;color:#859094;">产品名称:   {{ form.name }}</div></td>
                  <td><div class="cell" style="margin-top:10px;font-size:13px;color:#859094;">资源空间:   {{ spacename }}</div></td>
                  <td/>
                </tr>
                <tr >
                  <td><div class="cell" style="margin-top:10px;font-size:13px;color:#859094;">设备类型:   {{ form.device_type }}</div></td>
                  <td><div class="cell" style="margin-top:10px;font-size:13px;color:#859094;">协议类型:   {{ protocolname }}</div></td>
                  <td/>
                </tr>   
                <tr >
                  <td><div class="cell" style="margin-top:10px;font-size:13px;color:#859094;">厂商名称:   {{ form.firms_name }}</div></td>
                  <td><div class="cell" style="margin-top:10px;font-size:13px;color:#859094;">所属行业:   {{ firmsname }}</div></td>
                  <td/>
                </tr>   
                <tr >
                  <td><div class="cell" style="margin-top:10px;font-size:13px;color:#859094;">数据格式:   {{ datatype }}</div></td>
                  <td><div class="cell" style="margin-top:10px;font-size:13px;color:#859094;">创建时间:   {{ createtime }}</div></td>
                  <td/>
                </tr>         
              </tbody>
            </table>
          </div>        
                 
        </el-card>
      </el-col>
     <el-col :span="24" class="card-box">
        <el-card>
          <div slot="header">
            <div style="display:inline-block;font-weight:bolder;">产品服务</div> 
          </div>
          <el-tabs v-model="activeTab">
            <el-tab-pane label="模型定义" name="productModel" :lazy="true">
              <productModel :product="productid" />
            </el-tab-pane>
            <el-tab-pane label="Topic管理" name="productTopic" :lazy="true">
              <productTopic :product="productid" />
            </el-tab-pane>
            <el-tab-pane label="在线调试" name="productDebug" :lazy="true">
              <productDebug :product="form" />
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </el-col>      
    </el-row>

    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="700px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">  
        <el-form-item label="产品名称" prop="name" :required=true>
          <el-input v-model="form.name" placeholder="产品名称" />
        </el-form-item>        
        <el-form-item label="厂商名称" prop="firms_name" :required=true>
          <el-input v-model="form.firms_name" placeholder="产品名称" />
        </el-form-item>        
        <el-form-item label="产品描述" prop="remark">
          <el-input v-model="form.remark" type="textarea" placeholder="请输入产品描述信息" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import productModel from "./productModel";
import productTopic from "./productTopic";
import productDebug from "./productDebug";
import { listIotProduct, getIotProduct, addIotProduct, updateIotProduct, delIotProduct } from "@/api/iot/product";
import { listIotSpace, getIotSpace, addIotSpace, updateIotSpace, delIotSpace } from "@/api/iot/space";
export default {
  name: "ProductInfo",
  components: { productModel, productTopic, productDebug },
  data() {
    return {
      activeTab: "productModel",
      productid:0,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,       
      },
      loading: [],
      // 是否显示弹出层
      open: false,
      form: {},  
      title: "",
      deviceCount:0,
      spacename:"",
      protocolname:"",
      firmsname:"",
      datatype:"",
      createtime:"",
      rules: {
        name: [
          { required: true, message: "产品名称不能为空", trigger: "blur" }
        ],
        firms_name: [
          { required: true, message: "厂商名不能为空", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.productid = this.$route.params && this.$route.params.id;  
    this.getProduct();   
    this.openLoading();
  },
  methods: {
    getProduct() {
      getIotProduct(this.productid).then(response => {
        this.form = response.data;              
        this.createtime=this.timestampToTime(this.form.create_time);   
        getIotSpace(this.form.space_id).then(response2 => {       
          this.spacename = response2.data.space_name;  
        }).catch(function (error){
            console.log(error);
        }); 
        var listqueryParams={
          pageNum: 1,
          pageSize: 10,
          case_id: response.data.case_id,        
        };
        listIotProduct(listqueryParams).then(response3 => {         
          this.categoryList = response3.data.category_list;        
          response3.data.category_list.forEach(element => {
            if (this.form.firms_type == element.id)
              this.firmsname= element.field_name;        
          });
          response3.data.dataType_list.values.forEach(element => {
            if (this.form.data_type == element.key)
              this.datatype= element.value;        
          });
          response3.data.statusOptions.values.forEach(element => {
            if (this.form.protocol_id == element.key)
              this.protocolname= element.value;        
          });
        }).catch(function (error){
            console.log(error);
        });
        this.loading.close();     
      }).catch(function (error){
        console.log(error);
      }); 
    },    
    updateProduct(){
      this.open = true;
      this.title="更新产品信息";
    }, 
    /** 提交按钮 */
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != undefined) {            
            updateIotProduct(this.form).then(response => {
              if (response.code ===0) {
                this.msgSuccess("修改成功");
                this.open = false;                
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      //this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        name: undefined,
        product_code: undefined,        
        product_status: "1",
        remark: undefined
      };
      this.resetForm("form");
    },
    timestampToTime (cjsj) {
        var date = new Date(cjsj*1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-'
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
        var D = date.getDate() + ' '
        var h = date.getHours() + ':'
        var m = date.getMinutes() + ':'
        var s = date.getSeconds()
        return Y+M+D+h+m+s        
    },
    // 打开加载层
    openLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "获取中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
    }
  }
};
</script>