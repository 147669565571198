<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px">      
      <el-form-item label="Topic类型" prop="is_system">
        <el-select v-model="queryParams.is_system" placeholder="Topic类型" clearable size="small" @change="isSystemChange">
          <el-option
            v-for="dict in isSystemList"
            :key="dict.key"
            :label="dict.value"
            :value="dict.key"
          />
        </el-select>
      </el-form-item> 
    </el-form>
    <el-row/>

    <vxe-grid       
            ref="xGrid"
            stripe
            width="100%"
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="topicList"
            :column-config="{resizable: false}"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent">
      <template #toolbar_buttons v-if="queryParams.is_system > 0">
        <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >新增</el-button>
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
        >修改</el-button>
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
        >删除</el-button>
      </template>

      <!--默认操作按钮-->
      <template #defaultopr="{ row }" v-if="queryParams.is_system > 0">
        <el-button  size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)"
        >修改
        </el-button>
        <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(row)"
        >删除
        </el-button>
      </template>

      <!--自定义空数据模板-->
      <template #empty>
            <span >
              <p>暂无数据</p>
            </span>
      </template>

    </vxe-grid>
    <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange">
    </vxe-pager>


    <!-- 添加或修改分类对话框 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="名称" prop="top_name">
          <el-input v-model="form.top_name" placeholder="名称" />
        </el-form-item>
        <el-form-item label="主题路径" prop="path_name">
          <el-input v-model="form.path_name" placeholder="主题路径" />
        </el-form-item>        
        <el-form-item label="类型" prop="topic_type">
          <el-select v-model="form.topic_type" placeholder="类型" clearable size="small">
            <el-option
              v-for="dict in statusOptions"
              :key="dict.key"
              :label="dict.value"
              :value="dict.key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="功能描述" prop="usefor_des">
          <el-input v-model="form.usefor_des" type="textarea"  placeholder="功能描述" />
        </el-form-item>        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listIotTopic, getIotTopic, addIotTopic, updateIotTopic, delIotTopic } from "@/api/iot/topic";

export default {
  props: {
    product: {
      type: String
    }
  },
  name: "IotTopic",
  data() {
    return {
      // 工具栏
      tableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      isSystemList:[{key:0,value:"系统预置Topic"},{key:1,value:"自定义Topic"}],
      // 列
      tableColumn: [
        {type: 'checkbox', width: 60, fixed: "left" },
        {field: 'id', title: '编号', width: 100, fixed: "left"},
        {field: 'categary', title: 'Topic分类' },
        {field: 'top_name', title: 'topic' },
        {field: 'topic_type', title: '类型' ,formatter:this.TopicFormat },
        {field: 'usefor_des', title: '功能' },
        {field: 'remark', title: '说明' },
        {field: '', title: '操作',resizable:false, width: 180, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 分类表格数据
      topicList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 状态数据字典
      statusOptions: [],      
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        product_id: undefined,
        is_system: 0
      },      
      // 表单参数
      form: {},
      // 表单校验
      rules: {        
        top_name: [
          { required: true, message: "名称不能为空", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    if(this.product!=undefined){      
      this.queryParams.product_id=parseInt(this.product);
      this.getList();
    }    
  },
  methods: {
    handleSizeChange ({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if(this.queryParams.pageSize == pageSize){
        this.getList();
      }else{
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }

    },
    checkboxChangeEvent ({ records }) {
      this.ids = records.map(item => item.id)
      this.single = records .length!=1;
      this.multiple = !records .length

    },

    /** 查询分类列表 */
    getList() {
      this.loading = true;
      listIotTopic(this.queryParams).then(response => {
        this.single = true;
        this.multiple = true;
        this.topicList = response.data.list;        
        this.total = response.data.total;
        this.statusOptions = response.data.statusOptions.values;        
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        product_id: this.queryParams.product_id,
        top_name: undefined,
        path_name: undefined,
        topic_type: "1",
        usefor_des: undefined,
        is_system:1,
        remark: undefined
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!=1
      this.multiple = !selection.length
    },
    //变换选择
    isSystemChange(){      
      this.getList();
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加主题";
    },
      
    TopicFormat({row}){
      return this.selectDictLabel(this.statusOptions, row.topic_type + '');
    },

    /** 修改按钮操作 */
    handleUpdate(row) {

      this.reset();
      const id = row.id || this.ids
      getIotTopic(id).then(response => {
        this.form = response.data;
        let data = response.data
        if (data) {
          this.form = {
            id: data.id,                     
            top_name: data.top_name,
            path_name: data.path_name,
            topic_type: data.topic_type,
            usefor_des: data.usefor_des,
            remark: data.remark
          }
        }
        this.open = true;
        this.title = "修改主题";
      });
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != undefined) {
            updateIotTopic(this.form).then(response => {
              if (response.code ===0) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            this.form.product_id=this.queryParams.product_id;
            this.form.is_system=1;
            addIotTopic(this.form).then(response => {
              if (response.code ===0) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || (this.ids ? this.ids.join(',') : '');
      this.$confirm('是否确认删除主题编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delIotTopic(ids);
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    }
  }
};
</script>
