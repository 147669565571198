<template>
  <div style="height: 600px;">
    <div style="position: relative;">
      <el-button                               
        style="margin-left: 10px"                                  
        size="mini"     
        type="danger"    
        @click="handleAddService"
        >添加服务</el-button>
      <el-button                
        style="margin-left: 10px"                                  
        size="mini"         
                               
        >导入库模型</el-button>
      <el-button                
        style="margin-left: 10px"
        size="mini"
       
        >上传模型文件</el-button>
      <el-button                
        style="margin-left: 10px"
        size="mini"
                           
        >Excel导入</el-button>
      <div style=" display:inline-block ; float:right">
        <router-link size="mini" :to="'#'" class="link-type" style=" display:inline-block ;" >
          <span style="font-size:12px" >关于产品模型 ？</span>
        </router-link>
        <el-button                
          style="margin-left: 10px"    
          size="mini"
                           
          >导出</el-button>               
      </div>
      <!-- <div  style = "clear:both;" >icon="el-icon-refresh"   ;border:1px solid #000 </div > -->
    </div>
    <el-row >
      <el-col :span="24" class="card-box">  
        <el-col :span="6">          
          <div >             
            <div slot="header" style="display:inline-block;margin-top:15px;width:100%;" >              
              <div style="display:inline-block;float:left;margin-top: 20px" >
                服务列表
              </div> 
              <div style="margin-top:15px; float:right;"> 
                <el-button                                 
                  icon="el-icon-plus"
                  size="mini"
                  @click="handleAddService"
                ></el-button>
                <el-button                                
                  icon="el-icon-refresh"
                  size="mini"
                  @click="handleRefreshService"
                ></el-button>
              </div>
            </div>            
          </div>
          <vxe-table            
            ref="sTable"
            height="500"           
            border
            width="100%"
            show-overflow
            highlight-hover-row
            highlight-current-row
            :row-config="{isCurrent: true, isHover: true}"
            :show-header="false"  
            :data="serviceList"   
            :column-config="{resizable: false}"
            @current-change="tableRow" 
          >          
          <vxe-table-column field="service_name"></vxe-table-column>
          </vxe-table>
        </el-col>
        <el-col :span="18">
          <el-row style="display:inline-block;margin-left:20px;margin-top:20px;height:60px;width:100%;background-color:rgb(248,248,249);">
            <div style="font-size:14px;display:inline-block;margin-top:20px;margin-left:10px;" >
              <div style="display:inline-block;color:#859094;" >
                服务ID:
              </div>
              <div style="display:inline-block;width:100px;" >
                {{chooserow.id}}
              </div>
              <div style="display:inline-block;color:#859094;margin-left:10px;" >
                服务名称:
              </div>
              <div style="display:inline-block;width:100px;" >
                {{chooserow.service_name}}
              </div>
              <div style="display:inline-block;color:#859094;margin-left:10px;" >
                服务描述:
              </div>
              <div style="display:inline-block;" >
                {{chooserow.remark}}
              </div>
            </div>
            <div style="font-size:14px;display:inline-block;margin-top:15px;margin-right:20px;float:right;" >
              <el-button
                  type="text"
                  icon="el-icon-edit"
                  size="mini"
                  @click="updateServiceBtn"                  
                >修改服务</el-button>
              <el-button
                  type="text"
                  icon="el-icon-delete"
                  size="mini"                  
                  @click="delServiceBtn"
                >删除服务</el-button>
            </div >
          </el-row>
          <el-row style="display:inline-block;margin-left:20px;margin-top:10px;height:300px;width:100%;">
            <vxe-grid
                    
                    ref="attributeTable"
                    height="300"
                    stripe
                    width="100%"
                    show-overflow
                    highlight-hover-row
                    :loading="attloading"
                    :toolbar-config="atttableToolbar"
                    :columns="atttableColumn"
                    :data="attributeList"
                    :column-config="{resizable: false}"
                    @checkbox-change="attcheckboxChangeEvent"
                    @checkbox-all="attcheckboxChangeEvent">
              <template #toolbar_buttons>
                <el-button
                  style="margin-left: 10px"
                  type="primary"
                  icon="el-icon-plus"
                  size="mini"
                  :disabled="addenable"
                  @click="attributeHandleAdd"
                >添加属性</el-button>
                <el-button
                  type="success"
                  icon="el-icon-edit"
                  size="mini"
                  :disabled="attsingle"
                  @click="attributeHandleUpdate"
                >修改</el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  :disabled="attmultiple"
                  @click="attributeHandleDelete"
                >删除</el-button>
              </template>              
              <!--默认操作按钮-->
              <template #defaultopr="{ row }">                
                <el-button size="mini" type="text" icon="el-icon-edit" @click="attributeHandleUpdate(row)"
                >修改
                </el-button>
                <el-button size="mini" type="text" icon="el-icon-delete" @click="attributeHandleDelete(row)"
                >删除
                </el-button>
              </template>

              <template #data_list="{ row }">
                <router-link :to="'/iot/product/info/'+row.id" class="link-type">
                  <span>{{ row.name }}</span>
                </router-link>
              </template>

              <!--自定义空数据模板-->
              <template #empty>
                    <span >
                      <p>暂无数据</p>
                    </span>
              </template>

            </vxe-grid>
            <vxe-pager
                    background
                    size="small"
                    :loading="attloading"
                    :current-page="attqueryParams.pageNum"
                    :page-size="attqueryParams.pageSize"
                    :total="atttotal"
                    @page-change="atthandleSizeChange">
            </vxe-pager>          
          </el-row>

          <!-- commandTable -->
          <el-row style="display:inline-block;margin-left:20px;margin-top:10px;height:300px;width:100%;">
            <vxe-grid
                    
                    ref="commandTable"
                    height="300"
                    stripe
                    width="100%"
                    show-overflow
                    highlight-hover-row
                    :loading="comloading"
                    :toolbar-config="comtableToolbar"
                    :columns="comtableColumn"
                    :data="commandList"
                    :column-config="{resizable: false}"
                    @checkbox-change="comcheckboxChangeEvent"
                    @checkbox-all="comcheckboxChangeEvent">
              <template #toolbar_buttons>
                <el-button
                  style="margin-left: 10px"
                  type="primary"
                  icon="el-icon-plus"
                  size="mini"
                  :disabled="addenable"
                  @click="commandHandleAdd"
                >添加命令</el-button>
                <el-button
                  type="success"
                  icon="el-icon-edit"
                  size="mini"
                  :disabled="comsingle"
                  @click="commandHandleUpdate"
                >修改</el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  :disabled="commultiple"
                  @click="commandHandleDelete"
                >删除</el-button>
              </template>              
              <!--默认操作按钮-->
              <template #defaultopr="{ row }">                
                <el-button size="mini" type="text" icon="el-icon-edit" @click="commandHandleUpdate(row)"
                >修改
                </el-button>
                <el-button size="mini" type="text" icon="el-icon-delete" @click="commandHandleDelete(row)"
                >删除
                </el-button>
              </template>

              <template #data_list="{ row }">
                <router-link :to="'/iot/product/info/'+row.id" class="link-type">
                  <span>{{ row.name }}</span>
                </router-link>
              </template>

              <!--自定义空数据模板-->
              <template #empty>
                    <span >
                      <p>暂无数据</p>
                    </span>
              </template>

            </vxe-grid>
            <vxe-pager
                    background
                    size="small"
                    :loading="comloading"
                    :current-page="comqueryParams.pageNum"
                    :page-size="comqueryParams.pageSize"
                    :total="comtotal"
                    @page-change="comhandleSizeChange">
            </vxe-pager>  
          </el-row>
        </el-col>
      </el-col>
    </el-row>
    <!-- 添加或修改商品服务 -->
    <el-dialog :close-on-click-modal="false" :title="servicetitle" :visible.sync="serviceopen" width="700px" append-to-body>
      <el-form ref="serviceform" :model="serviceform" :rules="servicerules" label-width="80px">  
        <el-form-item label="服务名称" prop="service_name" :required=true>
          <el-input v-model="serviceform.service_name" placeholder="服务名称" />
        </el-form-item>        
        <el-form-item label="服务类型" prop="service_type">
          <el-input v-model="serviceform.service_type" placeholder="服务类型" />
        </el-form-item>        
        <el-form-item label="服务描述" prop="remark">
          <el-input v-model="serviceform.remark" type="textarea" placeholder="请输入产品描述信息" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="servicesubmitForm">确 定</el-button>
        <el-button @click="servicecancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 添加或修改服务属性 -->
    <el-dialog :close-on-click-modal="false" :title="attributetitle" :visible.sync="attributeopen" width="700px" append-to-body>
      <el-form ref="attributeform" :model="attributeform" :rules="attributerules" label-width="120px">  
        <el-form-item label="属性名称" prop="name" :required=true>
          <el-input v-model="attributeform.name" placeholder="属性名称" />
        </el-form-item>        
        <el-form-item label="属性描述" prop="remark">
          <el-input v-model="attributeform.remark" type="textarea" placeholder="请输入描述信息" />
        </el-form-item>
        <el-form-item label="数据类型" prop="data_type" :required=true>
          <el-select v-model="attributeform.data_type" placeholder="数据类型" clearable size="small">
            <el-option
              v-for="(item,key) in attributedatatype"
              :key="key"
              :label="item.value"
              :value="key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="访问权限" prop="auth" :required=true>
          <el-select v-model="attributeform.auth" placeholder="数据类型" clearable size="small">
            <el-option
              v-for="(item,key) in attributeauth"
              :key="key"
              :label="item.value"
              :value="key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="取值范围下限" prop="min_range" :required=true>
          <el-input v-model="attributeform.min_range" placeholder="取值范围下限" />
        </el-form-item>
        <el-form-item label="取值范围上限" prop="range" :required=true>
          <el-input v-model="attributeform.range" placeholder="取值范围上限" />
        </el-form-item>
        <el-form-item label="步长" prop="service_type">
          <el-input v-model="attributeform.step" placeholder="步长" />
        </el-form-item>        
        <el-form-item label="单位" prop="unit">
          <el-input v-model="attributeform.unit" placeholder="单位" />
        </el-form-item> 
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="attributesubmitForm">确 定</el-button>
        <el-button @click="attributecancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 添加或修改服务命令 -->
    <el-dialog :close-on-click-modal="false" :title="commandtitle" :visible.sync="commandopen" width="800px" append-to-body>
      <el-form ref="commandform" :model="commandform" :rules="commandrules" label-width="80px">  
        <el-form-item label="命令名称" prop="name" :required=true>
          <el-input v-model="commandform.name" placeholder="命令名称" />
        </el-form-item> 
        <el-row style="display:inline-blockmargin-top:10px;height:300px;width:100%;">
          <vxe-grid
                    
                    ref="sendattributeTable"
                    height="300"
                    stripe
                    show-overflow
                    highlight-hover-row
                    :loading="sendattloading"
                    :toolbar-config="atttableToolbar"
                    :columns="atttableColumn"
                    :data="sendattributeList"
                    :column-config="{resizable: false}"
                    @checkbox-change="attcheckboxChangeEvent"
                    @checkbox-all="attcheckboxChangeEvent">
              <template #toolbar_buttons>
                <el-button
                  style="margin-left: 10px"
                  type="primary"
                  icon="el-icon-plus"
                  size="mini"
                  :disabled="addenable"
                  @click="sendattributeHandleAdd"
                >添加下发参数</el-button>                
              </template>              
              <!--默认操作按钮-->
              <template #defaultopr="{ row }">                
                <el-button size="mini" type="text" icon="el-icon-edit" @click="attributeHandleUpdate(row)"
                >修改
                </el-button>
                <el-button size="mini" type="text" icon="el-icon-delete" @click="attributeHandleDelete(row)"
                >删除
                </el-button>
              </template>

              <template #data_list="{ row }">
                <router-link :to="'/iot/product/info/'+row.id" class="link-type">
                  <span>{{ row.name }}</span>
                </router-link>
              </template>

              <!--自定义空数据模板-->
              <template #empty>
                    <span >
                      <p>暂无数据</p>
                    </span>
              </template>

            </vxe-grid>
        </el-row>
        <el-row style="display:inline-blockmargin-top:10px;height:300px;width:100%;">
          <vxe-grid
                    
                    ref="responeattributeTable"
                    height="300"
                    stripe
                    width="100%"
                    show-overflow
                    highlight-hover-row
                    :loading="responeattloading"
                    :toolbar-config="atttableToolbar"
                    :columns="atttableColumn"
                    :data="responeattributeList"
                    :column-config="{resizable: false}"
                    @checkbox-change="attcheckboxChangeEvent"
                    @checkbox-all="attcheckboxChangeEvent">
              <template #toolbar_buttons>
                <el-button
                  style="margin-left: 10px"
                  type="primary"
                  icon="el-icon-plus"
                  size="mini"
                  :disabled="addenable"
                  @click="responeattributeHandleAdd"
                >添加响应参数</el-button>                
              </template>              
              <!--默认操作按钮-->
              <template #defaultopr="{ row }">                
                <el-button size="mini" type="text" icon="el-icon-edit" @click="attributeHandleUpdate(row)"
                >修改
                </el-button>
                <el-button size="mini" type="text" icon="el-icon-delete" @click="attributeHandleDelete(row)"
                >删除
                </el-button>
              </template>

              <template #data_list="{ row }">
                <router-link :to="'/iot/product/info/'+row.id" class="link-type">
                  <span>{{ row.name }}</span>
                </router-link>
              </template>

              <!--自定义空数据模板-->
              <template #empty>
                    <span >
                      <p>暂无数据</p>
                    </span>
              </template>

            </vxe-grid>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="commandsubmitForm">确 定</el-button>
        <el-button @click="commandcancel" v-if="commandform.id>0?false:true">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listIotProductService, getIotProductService, addIotProductService, updateIotProductService, delIotProductService } from "@/api/iot/productservice";
import { listIotServiceAttribute, getIotServiceAttribute, addIotServiceAttribute, updateIotServiceAttribute, delIotServiceAttribute ,delIotServiceAttributeByCommandId} from "@/api/iot/serviceattribute";
import { listIotServiceCommand, getIotServiceCommand, addIotServiceCommand, updateIotServiceCommand, delIotServiceCommand } from "@/api/iot/servicecommand";
export default {
  props: {
    product: {
      type: String
    }
  },
  data() {   
    return {
      atttableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      comtableToolbar: {
        perfect: true,
        zoom: true,
        custom: true,
        refresh: {
          query: this.handleQuery
        },
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      addenable:true,
      // 遮罩层
      attloading: false,
      comloading: false,
      sendattloading:false,
      responeattloading:false,
      // 选中数组
      attids: [],
      comids: [],
      // 非单个禁用
      attsingle: true,
      comsingle: true,
      // 非多个禁用
      attmultiple: true,
      commultiple: true,
      // 总条数
      atttotal: 0,
      comtotal: 0,
      sevicequeryParams: {
        pageNum: 1,
        pageSize: 10,
        product_id:undefined
      },
      attqueryParams: {
        pageNum: 1,
        pageSize: 10,
        service_id: undefined,
        device_id: undefined,
        rule_id: undefined,
        command_id:0
      },
      comqueryParams: {
        pageNum: 1,
        pageSize: 10,
        service_id: undefined,        
        rule_id: undefined
      },
      //服务list
      serviceList:[],
      serviceopen:false,
      servicetitle:"添加服务",
      serviceform:{},
      chooserow:{},
      chooseid:undefined,      
      servicerules:{ 
        service_name: [
          { required: true, message: "服务名称不能为空！", trigger: "blur" }
        ]
      },
      //属性
      attributeList:[],
      sendattributeList:[],
      responeattributeList:[],
      attributeopen:false,
      attributetitle:"",
      attributeform:{}, 
      attributedatatype:[], 
      attributeauth:[{key:0,value:"仅可读"},{key:1,value:"仅可写"},{key:2,value:"可读、可写"}], 
      attributerules:{ 
        name: [
          { required: true, message: "属性名称不能为空！", trigger: "blur" }
        ],
        range: [
          { required: true, message: "取值范围不能为空！", trigger: "blur" }
        ]
      },
      //命令
      commandList:[],
      commandopen:false,
      commandtitle:"",
      commandform:{}, 
      commandrules:{ 
        name: [
          { required: true, message: "属性名称不能为空！", trigger: "blur" }
        ]
      },            
       // 列
      atttableColumn: [
        {type: 'checkbox', width: 60, fixed: "left"},
        {field: 'name', title: '属性名称'},        
        {field: 'data_type', title: '数据类型' ,formatter:this.dataFormat},
        {field: 'auth', title: '访问方式',formatter:this.authFormat },
        {field: 'remark', title: '描述' },     
        {field: '', title: '操作',resizable:false, width: 160, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
      comtableColumn: [
        {type: 'checkbox', width: 60, fixed: "left"},
        {field: 'name', title: '命令名称'},
        {field: 'send_attribute', title: '下发参数' },
        {field: 'response', title: '响应参数' },        
        {field: '', title: '操作',resizable:false, width: 160, fixed: "right", align: "center", slots: {default: 'defaultopr'}}
      ],
    };    
  }, 
  created() {   
    if(this.product != undefined){
      this.sevicequeryParams.product_id=parseInt(this.product);
      this.getServiceList();
      this.getAttributeList();
      this.getCommandList();
    }else{
      this.msgError("获取产品信息失败！");
    }
    
  },
  methods: {
    getServiceList(){
      listIotProductService(this.sevicequeryParams).then(response =>{
        this.serviceList=response.data.list;
      })
    },
    getAttributeList(){
      if(this.chooseid!=undefined){
        this.attqueryParams.service_id=this.chooseid;
        this.attqueryParams.command_id=0;
        this.attqueryParams.rule_id=0;
        listIotServiceAttribute(this.attqueryParams).then(response =>{
          if (response.code ===0){
            this.attributeList=response.data.list;
            this.atttotal = response.data.total;
            this.attributedatatype = response.data.statusOptions.values;  
          }else {
            this.msgError(response.msg);
          }
        })
      }
    },
    getCommandList(){
      if(this.chooseid!=undefined){
        this.comqueryParams.service_id=this.chooseid;
        listIotServiceCommand(this.comqueryParams).then(response =>{
          if (response.code ===0){
            this.commandList=response.data.list;
            this.comtotal = response.data.total;            
          }else {
            this.msgError(response.msg);
          }
        })
      }
    },
    getSendAttributeList(commandid){
      if(this.chooseid!=undefined){    
        var sendattr={
            pageNum: 1,
            pageSize: 10,
            service_id: this.chooseid,
            device_id: undefined,
            rule_id: -1,
            command_id:commandid
        }
        
        listIotServiceAttribute(sendattr).then(response =>{
            if (response.code ===0){
              this.sendattributeList=response.data.list;         
            }else {
              this.msgError(response.msg);
            }
          });        
      }
    },
    getResponeAttributeList(commandid){
      if(this.chooseid!=undefined){
       var attr={
            pageNum: 1,
            pageSize: 10,
            service_id: this.chooseid,
            device_id: undefined,
            rule_id: -2,
            command_id:commandid
        }
        listIotServiceAttribute(attr).then(response =>{
          if (response.code ===0){
            this.responeattributeList=response.data.list;               
          }else {
            this.msgError(response.msg);
          }
        })
      }
    },
    dataFormat({row}){
      this.labname="";
      this.attributedatatype.forEach(element => {
        if (row.data_type == element.key)
          this.labname= element.value;        
      });
      return this.labname;
    },
    authFormat({row}){
      this.labname="";
      this.attributeauth.forEach(element => {
        if (row.data_type == element.key)
          this.labname= element.value;        
      });
      return this.labname;
    },
    attcheckboxChangeEvent ({ records }) {
      this.attids = records.map(item => item.id)
      this.attsingle = records .length!=1;
      this.attmultiple = !records .length
    },
    comcheckboxChangeEvent ({ records }) {
      this.comids = records.map(item => item.id)
      this.comsingle = records .length!=1;
      this.commultiple = !records .length
    },
    comhandleSizeChange(){},
    atthandleSizeChange(){}, 

    //添加属性
    attributeHandleAdd(){         
      this.attributetitle = "添加属性";
      this.attributeopen = true;
      this.attributereset();   
      this.attributeform.rule_id=0;   
    },   
    //添加下发参数
    sendattributeHandleAdd(){
      this.attributetitle = "添加属性";      
      this.attributereset();
      this.attributeform.rule_id=-1;
      this.attributeopen = true;
    }, 
    //添加响应参数
    responeattributeHandleAdd(){
      this.attributetitle = "添加属性";      
      this.attributereset();
      this.attributeform.rule_id=-2;
      this.attributeopen = true;
    }, 
    attributereset(){
      this.attributeform = {
        id: undefined,
        name: undefined,
        data_type: 1,        
        auth: 0,
        min_range: 0,
        range: 1000,
        rule_id:0,
        remark: undefined
      };
      this.resetForm("attributeform");
    },
    //添加命令
    commandHandleAdd(){
      this.commandtitle = "添加命令";
      this.getSendAttributeList(0);
      this.getResponeAttributeList(0);      
      this.commandopen = true;
      this.commandreset();      
    },    
    commandreset(){
      this.commandform = {
        id: undefined,
        service_id:undefined,
        name: undefined,
        send_attribute: "",        
        response: "",
        rule_id:undefined,
        remark: undefined
      };
      this.resetForm("commandform");
    },   
    //添加服务
    handleAddService(){
      this.servicereset();
      this.serviceopen = true;      
    },
    handleRefreshService(){
      this.getServiceList();
      this.msgSuccess("刷新成功！");
    },
    servicereset() {
      this.serviceform = {
        id: undefined,
        service_name: undefined,
        service_type: undefined,  
        remark: ""
      };      
    },
    updateServiceBtn(){
      if(this.chooseid!=undefined){
        getIotProductService(this.chooseid).then(response=>{
          this.serviceform=response.data;          
          this.serviceopen = true;
        });
      }
    },
    delServiceBtn(){
      if(this.chooseid!=undefined){
        delIotProductService(this.chooseid).then(response=>{
            if (response.code ===0) { 
                this.chooseid=undefined;
                this.chooserow={};
                this.addenable=true;
                this.msgSuccess("删除服务成功！"); 
                this.getServiceList();
              } else {
                this.msgError(response.msg);
              }
        });
      }else{
        this.msgError("获取选择的服务ID信息失败！");
      }
    },
    //点击服务
    tableRow({row}){      
      if(row.id!=undefined){        
        this.serviceform=row;
        this.chooseid=row.id;
        this.addenable=false;
      }        
      this.getServiceList();
      this.getChooseForm();
      this.getAttributeList();
      this.getCommandList();
    },
    getChooseForm(){
      if(this.chooseid!=undefined){
        getIotProductService(this.chooseid).then(response=>{
          this.chooserow=response.data;          
        });
      }else{
        this.msgError("获取服务ID信息失败！");
      }
      
    },
    servicesubmitForm() {
      this.$refs["serviceform"].validate(valid => {
        if (valid) {
          if (this.serviceform.id != undefined) {            
            updateIotProductService(this.serviceform).then(response => {
              if (response.code ===0) {
                this.msgSuccess("修改成功");
                this.serviceopen = false;
                this.getServiceList();
                this.getChooseForm();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {         
            this.serviceform.product_id=this.sevicequeryParams.product_id;
            addIotProductService(this.serviceform).then(response => {
              if (response.code ===0) {
                this.msgSuccess("新增成功");
                this.serviceopen = false;
                this.getServiceList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    servicecancel(){
      this.serviceopen = false;
      this.servicereset();  
      this.getServiceList();      
    },
    //添加属性确定
    attributesubmitForm() {
      this.$refs["attributeform"].validate(valid => {
        if (valid) {
          if (this.attributeform.id != undefined) {
            updateIotServiceAttribute(this.attributeform).then(response => {
              if (response.code ===0) {
                this.msgSuccess("修改属性成功");
                if(this.attributeform.rule_id==0)               
                  this.getAttributeList();
                if(this.attributeform.rule_id==-1)               
                  this.getSendAttributeList();                
                this.attributeopen = false;                
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            if(this.commandform.id != undefined || this.commandform.id > 0)
              this.attributeform.command_id=this.commandform.id;
            else
              this.attributeform.command_id=0;
            this.attributeform.service_id=this.chooseid;            
            addIotServiceAttribute(this.attributeform).then(response => {
              if (response.code ===0) {
                this.msgSuccess("新增属性成功");
                if(this.attributeform.rule_id==0)               
                  this.getAttributeList();
                else if(this.attributeform.rule_id==-1){
                  this.getSendAttributeList(this.attributeform.command_id);
                }
                else if(this.attributeform.rule_id==-2){
                  this.getResponeAttributeList(this.attributeform.command_id);
                }                
                this.attributeopen = false;
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    //添加属性取消
    attributecancel(){
      this.attributeopen = false;
      this.attributereset();
    },
    attributeHandleUpdate(row) {
      this.attributereset();
      const id = row.id || this.attids
      getIotServiceAttribute(id).then(response => {
        this.attributeform = response.data;
        this.attributeopen = true;
        this.attributetitle = "修改产品";
      });
    },
    attributeHandleDelete(row) {
      const attids = row.id || (this.attids ? this.attids.join(',') : '');
      this.$confirm('是否确认删除属性编号为"' + attids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delIotServiceAttribute(attids);
        }).then(() => {
          this.getAttributeList();
          this.getSendAttributeList();
          this.getResponeAttributeList();
          this.msgSuccess("删除成功");
        }).catch(function() {});
    },
    //添加命令确实
    commandsubmitForm() {
      this.$refs["commandform"].validate(valid => {
        if (valid) {
            this.commandform.send_attribute="";
            this.sendattributeList.forEach(element => {            
              this.commandform.send_attribute=this.commandform.send_attribute+element.name;
              this.commandform.send_attribute=this.commandform.send_attribute+(',');
            }); 
            this.commandform.response="";
            this.responeattributeList.forEach(element => {            
              this.commandform.response=this.commandform.response+element.name;
              this.commandform.response=this.commandform.response+(',');
            }); 
            if(this.commandform.send_attribute!=""){
              this.commandform.send_attribute=this.commandform.send_attribute.substr(0,this.commandform.send_attribute.length-1);
            }
            if(this.commandform.response!=""){
              this.commandform.response=this.commandform.response.substr(0,this.commandform.response.length-1);
            }
          if (this.commandform.id != undefined ||this.commandform.id > 0) {
           
            updateIotServiceCommand(this.commandform).then(response => {
              if (response.code ===0) {
                this.msgSuccess("修改属性成功");                
                this.commandreset();
                this.getCommandList();
                this.commandopen = false;                
              } else {
                this.msgError(response.msg);
              }
            });
          } else {         
            this.commandform.service_id=this.chooseid;            
            addIotServiceCommand(this.commandform).then(response => {
              if (response.code ===0) {
                this.msgSuccess("新增属性成功");
                //命令添加后更新属性列表属性值//command_id//command_type
                this.sendattributeList.forEach(element => {            
                  element.command_id=response.data.id;
                  element.command_type=0;
                  updateIotServiceAttribute(element);
                });
                this.responeattributeList.forEach(element => {            
                  element.command_id=response.data.id;
                  element.command_type=1;
                  updateIotServiceAttribute(element);
                });
                this.commandreset();
                this.getCommandList();
                this.commandopen = false;
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    commandcancel(){
      
      if (this.commandform.id != undefined){
          
        }else{          
          var sids=""
          this.sendattributeList.forEach(element => {            
            sids=sids+element.id;
            sids=sids+(',');
          });           
          this.responeattributeList.forEach(element => {            
            sids=sids+element.id;
            sids=sids+(',');
          });
          if(sids!=""){
            delIotServiceAttribute(sids).then(response=>{
              this.sendattributeList=[];
              this.responeattributeList=[];
            }); 
          }                   
      }    
      this.commandopen = false;
      this.commandreset();  
    },    
    commandHandleUpdate(row) {
      this.commandreset();
      const comids = row.id || this.comids
      getIotServiceCommand(comids).then(response => {
        this.commandform = response.data;
        this.commandopen = true;
        this.commandtitle = "修改命令";
        this.attqueryParams.command_id=this.commandform.id;
        this.getSendAttributeList(comids);
        this.getResponeAttributeList(comids);
      });
    },
    commandHandleDelete(row) {
      const comids = row.id || (this.comids ? this.comids.join(',') : '');
      this.$confirm('是否确认删除属性编号为"' + comids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          delIotServiceAttributeByCommandId(comids);
        }).then(() => {
          delIotServiceCommand(comids).then(resp=>{
            this.getCommandList();
            this.msgSuccess("删除成功");
          });
          
        }).catch(function() {});
    },
  }
};
</script>
<style scoped>
tr.focus{
  background-color:rgb(78, 85, 168);
}
</style>

