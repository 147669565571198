import request from '@/utils/request'

// 添加固件消息
export function addIotTopic(data) {
    return request({
      url: '/iot/topic/add',
      method: 'post',
      data: data
    })
}

// 获取固件消息
export function getIotTopic(id) {
    return request({
      url: '/iot/topic/get?id=' + id,
      method: 'get'
    })
}

// 更新固件消息
export function updateIotTopic(data) {
    return request({
      url: '/iot/topic/update',
      method: 'post',
      data: data
    })
  }
  
// 删除固件消息
export function delIotTopic(id) {
    return request({
      url: '/iot/topic/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 固件消息列表
export function listIotTopic(query) {
  return request({
    url: '/iot/topic/list',
    method: 'get',
    params: query
  })
}