import request from '@/utils/request'

// 添加产品服务属性
export function addIotServiceAttribute(data) {
    return request({
      url: '/iot/serviceattribute/add',
      method: 'post',
      data: data
    })
}

// 获取产品服务属性
export function getIotServiceAttribute(id) {
    return request({
      url: '/iot/serviceattribute/get?id=' + id,
      method: 'get'
    })
}

// 更新产品服务属性
export function updateIotServiceAttribute(data) {
    return request({
      url: '/iot/serviceattribute/update',
      method: 'post',
      data: data
    })
  }
  
// 删除产品服务属性
export function delIotServiceAttribute(id) {
    return request({
      url: '/iot/serviceattribute/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }

// 删除产品服务命令属性
export function delIotServiceAttributeByCommandId(id) {
  return request({
    url: '/iot/serviceattribute/delbycommand',
    method: 'post',
    data: {
      ids: id
    }
  })
}
// 产品服务属性列表
export function listIotServiceAttribute(query) {
  return request({
    url: '/iot/serviceattribute/list',
    method: 'get',
    params: query
  })
}